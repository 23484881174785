// require rails-ujs
// require activestorage
// require turbolinks
// require_tree
import { Turbo } from "@hotwired/turbo-rails"
import { Application } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import './jquery-global';
import 'jquery-ui-dist/jquery-ui';
import io from 'socket.io-client';

export function clearJson(json) {
    if (!json || json.data == null) {
        json = {};
        json.data = '';
    }
    return json
}
const application = Application.start();

/* Without Webpacker, the following would be needed instead, for each JS controller:
import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
// your controller code here ...
}
*/

// Switch on Rails UJS functionality
Rails.start();